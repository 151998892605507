import React, { useEffect } from "react";
import Lottie from 'react-lottie';
import '../styles/App.css';
import {
    Grid,
    Box,
    Button
} from "@material-ui/core";
import aniData from "../data/ani-globe.json";
import { useHistory } from "react-router-dom";
import serviceStatuses from "../data/disable-services";
import { Link as RouterLink } from "react-router-dom";

export default function Landing({handleSignIn}) {
  const history = useHistory();

  const aniOptions = {
    loop: false,
    autoplay: true,
    animationData: aniData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  
  // TODO: Fully implement the page for Vision services
  return (
    <div>
      <Grid container spacing={0} className="landingGrid">
        <Grid item xs={12}>
          <Lottie
            options={aniOptions}
            height={500}
            width={500}
          />
        </Grid>
        <Grid item xs={12}>
          <p className="landingDescription">Welcome to Lilly Translate! We are here to support all your language needs.</p>
          <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
            <Button variant="contained" size="large" color="primary" onClick={ () => history.push("/translate")} disabled={serviceStatuses.disableTranslate}>
              Translation
            </Button>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
              <RouterLink to="/speech" className="link">
                Speech
              </RouterLink>
              <span> | </span>
              <RouterLink to="/textanalysis" className="link">
                Text Analys
              </RouterLink>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}